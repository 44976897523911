import React, { useEffect, useState } from "react"
import styled from "styled-components"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Button from "../../components/Reusable/Button"

import { isBrowser, getParameterByName } from "../../services/utils"

const Styles = styled.div`
  text-align: center;
  padding-bottom: 20px;

  h1 {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  p {
    padding-bottom: 10px;
  }
`

export default function PaymentFinishPage() {
  const [orderId, setOrderId] = useState("")
  const [transactionStatus, setTransactionStatus] = useState("")

  useEffect(() => {
    if (isBrowser()) {
      setOrderId(getParameterByName("order_id"))
      setTransactionStatus(getParameterByName("transaction_status"))
    }
  }, [])

  return (
    <Layout>
      <SEO title="Payment Not Finished" />
      <Styles className="layout">
        <h1>Payment Not Finished</h1>
        {orderId && transactionStatus ? (
          <p>
            Order Id: {orderId}
            <br />
            Transaction Status: {transactionStatus}
          </p>
        ) : (
          <></>
        )}
        <p>
          <Button to="/dashboard/">Open Dashboard</Button>
        </p>
      </Styles>
    </Layout>
  )
}
